import './App.css';
import Header from "./components/Header";
import FullSlider from "./components/FullSlider";
import Content from "./components/Content";
import Footer from "./components/Footer";

import PureCounter from "@srexi/purecounterjs";
const pure = new PureCounter();

function App() {
	
	// componentDidMount: {
		// const script = document.createElement("script");
		// script.src = "src/assets/js/main.js";
		// script.async = true;
		// document.body.appendChild(script);
	// }

	//let menu = require('./data/Products.json');
	
	return (
	<>
		<Header />
		<div className="container-fluid p-0">
			<FullSlider />
		</div>
		<div className="app-content">
			<Content />
		</div>
		<Footer />
	</>
	);
}

export default App;
