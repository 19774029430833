import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Product from "./pages/Product";

function Content() {
  return (
	<div>
		<AboutUs />
		<Product />
		<ContactUs />
	</div>
  );
}

export default Content;