import React, { useState } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

/**
[1] 26/08/2023 Update all product
*/

function ProductModalContent(iProdNo, iChildProdNo) {
	
	let productNumber = iProdNo;
	let childProductNumber = iChildProdNo;
	let title = '';
	
	let handleShow = (cTitle, pn, cpn) => {
		title = cTitle;
		productNumber = pn;
		childProductNumber = cpn;
		
		//console.log(title + " = " +productNumber + " = " +childProductNumber)
	}
	
	const productList = () => {
		let list = window.$menu;
		return list;
	}
	
	const modalProductDisplay = (productNumber, childProductNumber) => {
	
		return (
			productList().map((obj, index) => {
				
				if(obj.parent == productNumber) {
					
					if(Object.keys(obj.subProduct).length !== 0){
						
						if(typeof childProductNumber !== "undefined") {	
						
							// let childContent = obj.subProduct.filter(x => 
								// x.productContent.some(y => {
									// if(y.child == childProductNumber) {
										// return y.childContent
									// }
								// })
							// )
							
							return (
								<div>
								{
									 obj.subProduct.map((x) => {
										 
										 if(x.productContent.length > 0) {
											
											return (
												<div>
												{
													x.productContent.map((y) => {
														//console.log(y.child)
														 if(y.childContent && y.child == childProductNumber) {
															//console.log(y.childContent)
															return ( modalProductChildContentDisplay(y.childContent) )
														 }
														
													})
												}
												</div>
											)
										 }
										
									 })
								}
								</div>
							)
							
						} else {

							return (
								<Tabs
									defaultActiveKey={ obj.subProduct[index - 1].default === 1 ? obj.subProduct.headerName : '' }
									id="uncontrolled-tab-example"
									className="mb-3">
									{
										obj.subProduct.map((spObj, spIndex) => {
											return (
												//Header
												<Tab  key={ spIndex } eventKey={ spObj.headerName } title={ spObj.name }>
												{/* Start Content */}
												
												{ modalProductContentDisplay(spObj.productContent) }
												
												{/* End Content */}
												</Tab>
											)
										})
									}
								</Tabs>
							)
						}
					} {/* end subProduct */} 
				}
			})
		)
	}
	
	const modalProductContentDisplay = (productContent) => {
		
		if(productContent.length > 0) {
			return (
				<Row xs={1} md={2} className="g-4">
					{
						productContent.map((obj, index) => {
							return (
								<Col md={2}>
									<Card key={ index }>
										<div className="imgbox">
											<Card.Img variant="top" src={ obj.img } className="img-fluid mx-auto d-block w-50" alt=""  style={ obj.style } />
										</div>
										<Card.Body data-aos="zoom-in" className="d-none">
											<Card.Title>{ obj.name }</Card.Title>
											<Card.Text></Card.Text>
										</Card.Body>
										<Card.Footer className="text-center">
											<Button 
												data-aos="flip-up" 
												data-aos-duration="1500"
												variant="link" 
												size="sm"
												title="View more product"
												onClick={ () => handleShow( `${ obj.name }`, `${ obj.parent }`, `${ obj.child }`) }>{ obj.name }</Button>
										</Card.Footer>
									</Card>
								</Col>
							)
						})
					} 
				</Row>
			)
		} else {
			return (<p>No Product</p>)
		}
		
	}
	
	const modalProductChildContentDisplay = (childContent) => {
		
		if(childContent.length > 0) {
			console.log(childContent)
			return (
				<Tabs
					defaultActiveKey={ childContent[0].default === 1 ? childContent[0].headerName : '' }
					id="uncontrolled-tab-example"
					className="mb-3">
					{
						childContent.map((obj, index) => {
							return (
								//Header
								<Tab  key={ index } eventKey={ obj.headerName } title={ obj.name }>
								{/* Start Content */}
								
								No Product
								{/* End Content */}
								</Tab>
							)
						})
					}
				</Tabs>
			)
		} else {
			return (<p>No Product</p>)
		}
	}
	
	return modalProductDisplay(productNumber, childProductNumber);
}

export default ProductModalContent;