import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

/**
[1] 26/08/2023 Change Social Media links
				add tiktok
*/

function Footer() {
  return (
    <>
		<footer className="footer bg-dark text-white">
			<Container>
				<Row className="gy-3">
					<Col xs={12} md={3}>
						<h4><i className="bi bi-geo-alt icon"></i> Address</h4>
						<p>
							4th Floor Castro Bldg. 58 Timog Ave, Diliman, <br />
							Quezon City, Metro Manila - PH
						</p>
					</Col>
					<Col xs={12} md={3}>
						<h4><i className="bi bi-telephone icon"></i> Reservations</h4>
						<p>
							<strong>Landline:</strong> (02) 7000-1560
							<Row>
								<Col sm={3} md={2}><strong>Mobile:</strong></Col>
								<Col>+63 963 128 5777 <br/> +63 963 685 0287</Col>
							</Row>
							<Row>
								<Col sm={2}><strong>Email:</strong></Col>
								<Col>{/* Dragoncomtradingcorp@gmail.com <br/> */} contactus@dragoncomtrading.com</Col>
							</Row>
						</p>
					</Col>
					<Col xs={12} md={3}>
						<h4><i className="bi bi-clock icon"></i> Opening Hours</h4>
						<p>
							<strong>Mon-Sat:</strong> 9AM - 6PM; <br />
							<strong>Sunday:</strong> Closed
						</p>
					</Col>
					<Col xs={12} md={3}>
						<h4>Follow Us</h4>
						<div className="social-links d-flex">
							{/* <a href="#" className="twitter"><i className="bi bi-twitter"></i></a> */}
							<a href="https://www.facebook.com/DragoncomTrading/about_details/" className="facebook"><i className="bi bi-facebook"></i></a>
							<a href="https://www.tiktok.com/@dragoncomtco" className="tiktok"><i className="bi bi-tiktok"></i></a>
							{/* <a href="#" className="instagram"><i className="bi bi-instagram"></i></a>
							<a href="#" className="linkedin"><i className="bi bi-linkedin"></i></a> */}
						</div>
					</Col>
				</Row>
				
				<div className="copyright">
					&copy; Copyright <strong><span>Dragoncom Trading Corporation</span></strong>. All Rights Reserved 2023
				</div>
				<div className="credits">
					Developed by <a href="#">arpcats</a>
				</div>
			</Container>
		</footer>
    </>
  );
}

export default Footer;

