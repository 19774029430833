import React, {useEffect} from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'

import Card from 'react-bootstrap/Card';

import StatisticStatus from "../StatisticStatus";
import AOS from 'aos';

function AboutUs() {
	
	useEffect(() => {
		AOS.init();
	}, [])
	
	const details = () => {
		
		const list = [
			{
				icon: "<i class=\"bi bi-people text-danger\"></i>",
				footer: "About Us",
				description: "<p><strong>Dragoncom Trading Corporation</strong> is a retailer brand for " +
								"telecommunications equipment that was established in " +
								"2020 amidst the pandemic with its main office in Timog Avenue. </p> " +

								"<p>Our clients benefit from quality products and service we" +
								"provide that is reasonably priced. The mission of Dragoncom" +
								"Trading Corporation is to provide and offer brands of two-way" +
								"radio communication units and accessories to all people with" +
								"diverse perspectives while connecting and engaging with" +
								"local communities.</p>"
			},
			{
				icon: "<i class=\"bi bi-bullseye text-warning\"></i>",
				footer: "Mission",
				description: "<p>To provide quality, affordable and accessible communication equipment that will support LGUs, Small and medium enterprises and other " +
								"institutions in achieving strategix growth and efficiency through open channels while bridging the distance between communities.</p>"
			},
			{
				icon: "<i class=\"bi bi-eye text-info\"></i>",
				footer: "Vision",
				description: "<p>To be recognized as a reliable provider of quality communication equipment and services to LGUs, small and medium enterprises and other institutions aligned with our values.</p>"
			},
			
		];
		
		return list;
		
	}
	
	return (
	<div id="aboutUs" className="about-us">
		<div data-aos="fade-up">
			<h2 className="text-center page-title" data-aos="zoom-in-down" data-aos-duration="1500">Learn More <span>About Us</span></h2>
			<Container>
				{
					details().map((obj, index) => {
						return (
						<Card className="border-0 aboutUs-details" data-aos="fade-down" data-aos-duration="1500">
							<Card.Body>
								<Row className="gx-0">
									<Col xs={3} md={2} className="text-center" data-aos="fade-right" data-aos-duration="1500">
										<cite dangerouslySetInnerHTML={{ __html: obj.icon }}></cite>
									</Col>
									<Col xs={9} md={10} >
										<blockquote className="blockquote mb-0">
											<div dangerouslySetInnerHTML={{ __html: obj.description }} />

											<footer className="blockquote-footer">{ obj.footer }</footer>
										</blockquote>
									</Col>
								</Row>
							</Card.Body>
						</Card>
						) 
					})
				}
			</Container><br />
			
			<div className="mt-5">
				<StatisticStatus />
			</div>
		</div>
	</div>
	);
}

export default AboutUs;