import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'

function StatisticStatus() {
	
	return (
		<>
		<div data-aos="fade-up">
			 <section id="stats-counter" className="stats-counter">
				<Container data-aos="zoom-out">
					<Row className="gy-4"> 
						<Col sm={3} md={3}>
							<div className="stats-item text-center w-100 h-100">
							  <span data-purecounter-start="0" data-purecounter-end="232" data-purecounter-duration="1" className="purecounter">232</span>
							  <p>Clients</p>
							</div>
						</Col>
						<Col sm={3} md={3}>
							<div className="stats-item text-center w-100 h-100">
							  <span data-purecounter-start="0" data-purecounter-end="921" data-purecounter-duration="1" className="purecounter">921</span>
							  <p>Projects</p>
							</div>
						</Col>
						<Col sm={3} md={3}>
							<div className="stats-item text-center w-100 h-100">
							  <span data-purecounter-start="0" data-purecounter-end="1453" data-purecounter-duration="1" className="purecounter">1453</span>
							  <p>Hours Of Support</p>
							</div>
						</Col>
						<Col sm={3} md={3}>
							<div className="stats-item text-center w-100 h-100">
							  <span data-purecounter-start="0" data-purecounter-end="32" data-purecounter-duration="1" className="purecounter">35</span>
							  <p>Workers</p>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		</div>
		</>
	);
}

export default StatisticStatus;