import React, { useState } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
//import CardGroup from 'react-bootstrap/CardGroup';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import Modal from 'react-bootstrap/Modal';
//import ProductList from "../../components/ProductList";
import Carousel from 'react-bootstrap/Carousel';

/**
[1] 26/08/2023 Update all product
*/

function Product() {
	//const cardRef = useRef(null);
	const [title, setTitle] = useState('');
	const [productNumber, setProductNumber] = useState(0);
	const [childProductNumber, setChildProductNumber] = useState(0);
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	
	const handleShow = (title, productNumber, childProductNumber) => {
		setShow(true);
		setTitle(title);
		setProductNumber(productNumber);
		setChildProductNumber(childProductNumber);
	}
	
	const productList = () => {
		let list = window.$menu;
		return list;
	}
	
	const modalProductDisplay = (productNumber, childProductNumber) => {
	
		return (
			productList().map((obj, index) => {
				
				if(obj.parent == productNumber) {
					
					if(Object.keys(obj.subProduct).length !== 0){
						
						if(typeof childProductNumber !== "undefined") {	
						
							// let childContent = obj.subProduct.filter(x => 
								// x.productContent.some(y => {
									// if(y.child == childProductNumber) {
										// return y.childContent
									// }
								// })
							// )
							
							return (
								<div>
								{
									 obj.subProduct.map((x) => {
										 
										 if(x.productContent.length > 0) {
											
											return (
												<div>
												{
													x.productContent.map((y) => {
														//console.log(y.child)
														 if(y.childContent && y.child == childProductNumber) {
															//console.log(y.childContent)
															return ( modalProductChildContentDisplay(y.childContent) )
														 }
														
													})
												}
												</div>
											)
										 }
										
									 })
								}
								</div>
							)
							
						} else {

							return (
								<Tabs
									defaultActiveKey={ obj.subProduct[index - 1].default === 1 ? obj.subProduct.headerName : '' }
									id="uncontrolled-tab-example"
									className="mb-3">
									{
										obj.subProduct.map((spObj, spIndex) => {
											return (
												//Header
												<Tab  key={ spIndex } eventKey={ spObj.headerName } title={ spObj.name }>
												{/* Start Content */}
												
												{ modalProductContentDisplay(spObj.productContent) }
												
												{/* End Content */}
												</Tab>
											)
										})
									}
								</Tabs>
							)
						}
					} {/* end subProduct */} 
				}
			})
		)
	}
	
	const modalProductContentDisplay = (productContent) => {
		
		if(productContent.length > 0) {
			return (
				<Row xs={1} md={2} className="g-4">
					{
						productContent.map((obj, index) => {
							return (
								<Col md={2}>
									<Card key={ index }>
										<div className="imgbox">
											<Card.Img variant="top" src={ obj.img } className="img-fluid mx-auto d-block w-50" alt=""  style={ obj.style } />
										</div>
										<Card.Body data-aos="zoom-in" className="d-none">
											<Card.Title>{ obj.name }</Card.Title>
											<Card.Text></Card.Text>
										</Card.Body>
										<Card.Footer className="text-center">
											<Button 
												data-aos="flip-up" 
												data-aos-duration="1500"
												variant="link" 
												size="sm"
												title="View more product"
												onClick={ () => handleShow( `${ obj.name }`, `${ obj.parent }`, `${ obj.child }`) }>{ obj.name }</Button>
										</Card.Footer>
									</Card>
								</Col>
							)
						})
					} 
				</Row>
			)
		} else {
			return (<p>No Product</p>)
		}
		
	}
	
	const modalProductChildContentDisplay = (childContent) => {
		
		if(childContent.length > 0) {
			//console.log(childContent)
			return (
				<Tabs
					defaultActiveKey={ childContent[0].default === 1 ? childContent[0].headerName : '' }
					id="uncontrolled-tab-example"
					className="mb-3">
					{
						childContent.map((obj, index) => {
							return (
								//Header
								<Tab  key={ index } eventKey={ obj.headerName } title={ obj.name }>
								{/* Start Content */}
								
								No Product
								{/* End Content */}
								</Tab>
							)
						})
					}
				</Tabs>
			)
		} else {
			return (<p>No Product</p>)
		}
	}
	
	return (
		<div id="products">
			<h2 className="text-center page-title" data-aos="zoom-in-down" data-aos-duration="1500">Check <span>Our Products</span></h2>
			<Container>
				<div className="p-0 d-none d-md-block">
					<Row xs={1} md={2} className="g-4">
						{
							productList().map((obj, index) => {
								return (
									<Col md={3}>
										<Card key={ index } className="border-0">
											<div className="imgbox">
												<Card.Img 
													variant="top" 
													src={ obj.img } 
													className="img-fluid mx-auto d-block w-50" 
													alt={ obj.name } 
													style={ obj.style } 
													onClick={ () => handleShow( `${ obj.name }`, `${ obj.parent }` ) }/>
											</div>
											<Card.Body data-aos="zoom-in" className="d-none">
												<Card.Title>{ obj.name }</Card.Title>
												<Card.Text></Card.Text>
											</Card.Body>
											<Card.Footer className="text-center d-none">
												<Button 
													data-aos="flip-up" 
													data-aos-duration="1500"
													variant="link" 
													size="sm"
													title="View more product"
													onClick={ () => handleShow( `${ obj.name }`, `${ obj.parent }` ) }>{ obj.name }</Button>
											</Card.Footer>
										</Card>
									</Col>
								)
							})
						}
					</Row>
				</div>
				
				{/* --MOBILE-- */}
				<div className="d-block d-sm-none">
					<Carousel>
						{
							productList().map((obj, index) => {
								return (
									<Carousel.Item key={ index }>
										<Card>
											<Card.Img variant="top" src={ obj.img } className="img-fluid mx-auto d-block w-100" alt="" style={ obj.style }/>
											<Card.Body data-aos="zoom-in" className="d-none">
												<Card.Title>{ obj.name }</Card.Title>
												<Card.Text></Card.Text>
											</Card.Body>
											<Card.Footer className="text-center"> 
												<b>{ obj.name }</b>
												<Button 
													data-aos="flip-up" 
													data-aos-duration="1500"
													variant="link" 
													size="sm"
													onClick={ () => handleShow( `${ obj.name }`, `${ obj.parent }` ) }>View Products</Button>
											</Card.Footer>
										</Card>
									</Carousel.Item>
								)
							})
						}
					</Carousel>
				</div>
			</Container>
			
			<Modal 
				show={show} 
				fullscreen 
				onHide={() => setShow(false)}
				id="ModalChildProduct">
				<Modal.Header closeButton>
					<Modal.Title>{ title } Products</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="px-2">
						{ modalProductDisplay(productNumber, childProductNumber) }
					</div>
				</Modal.Body>
			</Modal>
	  
		
		</div>
	);
}

export default Product;