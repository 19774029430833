import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image'

function FullSlider() {
  return (
	<Carousel className="full-slider">
		<Carousel.Item style={{ backgroundColor: "#FAA51D"}}>
			<Image src={require('../assets/img/banner/hytera banner.jpg')} className="img-fluid mx-auto d-block" alt=""/> 
			<Carousel.Caption className="d-none d-md-block">
				<h3>Hytera Radios</h3>
				<p>Hytera</p>
			</Carousel.Caption>
		</Carousel.Item>
		<Carousel.Item style={{ backgroundColor: "#FAA51D"}}>
			<Image src={require('../assets/img/banner/Kenwood Banner.jpg')} className="img-fluid mx-auto d-block" alt=""/> 
			<Carousel.Caption className="d-none d-md-block">
				<h3>Kenwood Radios</h3>
				<p>Kenwood</p>
			</Carousel.Caption>
		</Carousel.Item>
		<Carousel.Item style={{ backgroundColor: "#FAA51D"}}>
			<Image src={require('../assets/img/banner/Cignus Banner.png')} className="img-fluid mx-auto d-block" alt=""/> 
			<Carousel.Caption className="d-none d-md-block">
				<h3>Cignus Radios</h3>
				<p>Security communications, Reliable, Dependable, High-Performance Two-Way Radio Communication.</p>
			</Carousel.Caption>
		</Carousel.Item>
		<Carousel.Item>
			<Image src={require('../assets/img/banner/radio3.jpg')} className="img-fluid mx-auto d-block" alt=""/> 
			<Carousel.Caption className="d-none d-md-block">
				<h3>Two Way Radio</h3>
				<p>Security communications, Reliable, Dependable, High-Performance Two-Way Radio Communication.</p>
			</Carousel.Caption>
		</Carousel.Item>
		<Carousel.Item>
			<Image src={require('../assets/img/banner/radio1.jpg')} className="img-fluid mx-auto d-block" alt=""/> 
			<Carousel.Caption className="d-none d-md-block">
				<h3>Two Way Radio</h3>
				<p>Security communications, Reliable, Dependable, High-Performance Two-Way Radio Communication.</p>
			</Carousel.Caption>
		</Carousel.Item>
		<Carousel.Item>
			<Image src={require('../assets/img/banner/radio2.jpg')} className="img-fluid mx-auto d-block" alt=""/> 
			<Carousel.Caption className="d-none d-md-block">
				<h3>Two Way Radio</h3>
				<p>Security communications, Reliable, Dependable, High-Performance Two-Way Radio Communication.</p>
			</Carousel.Caption>
		</Carousel.Item>
	</Carousel>
  );
}

export default FullSlider;