import React, { useEffect, useState } from 'react';
import {isMobile} from 'react-device-detect';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import Offcanvas from 'react-bootstrap/Offcanvas';
import ListGroup from 'react-bootstrap/ListGroup';

//import logo from '../assets/img/logo.jpg';
import logo from '../assets/img/dragoncom-logo.png'

//[1] 26/08/2023 change logo
/**
[1] 26/08/2023 change logo
				hide button of lazada and shopee
*/

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Modal from 'react-bootstrap/Modal';
import ProductModalContent from "./../data/ProductModalContent";


function Header() {
	
	//Mobile Settings 
	// const [show, setShow] = useState(false);
	// const handleClose = () => setShow(false);
	// const handleShow = () => setShow(false);
   
   
	let [title, setTitle] = useState('');
	let [productNumber, setProductNumber] = useState(0);
	let [childProductNumber, setChildProductNumber] = useState(0);
	let [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	
	let handleShow = (title, productNumber, childProductNumber) => {
		setShow(true);
		setTitle(title);
		setProductNumber(productNumber);
		setChildProductNumber(childProductNumber);
		
		//console.log(title + " = " +productNumber + " = " +childProductNumber + " = test")
	}
	
	
	const [hover, setHover] = useState(false);
   
	const menu = window.$menu;

	useEffect(() => {
			
		/**
		* Sticky header on scroll
		*/
		//const appHeader = document.querySelector('.app-header .navbar');
		const appHeader = document.querySelector('.app-header');
		if (appHeader) {
			document.addEventListener('scroll', () => {
				window.scrollY > 100 ? appHeader.classList.add('fixed-top') : appHeader.classList.remove('fixed-top');
			});
		}
		
		/**
		* Navbar links active state on scroll
		*/
		let navbarlinks = document.querySelectorAll('.app-header .nav-item a[href^="#"]');
		// function navbarlinksActive() {
			// navbarlinks.forEach(navbarlink => {
				// if (!navbarlink.hash) return;
				
				// let section = document.querySelector(navbarlink.hash);
				// if (!section) return;
				
				// let position = window.scrollY + 200;
				// if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
					// navbarlink.classList.add('active');
					// //let dataEventKey = navbarlink.getAttribute("data-rr-ui-event-key")
					// //document.getElementById(dataEventKey).style.paddingTop = position + "px"
					// //console.log(dataEventKey)
					// //console.log(section.offsetTop + " offsetTop")
					// //console.log(section.offsetHeight + " offsetHeight")
				// } else {
					// navbarlink.classList.remove('active');
				// }
			// })
		// }
		
		// window.addEventListener('load', navbarlinksActive);
		// document.addEventListener('scroll', navbarlinksActive);

		navbarlinks.forEach(a => {
			a.addEventListener('click', function (e) {
				e.preventDefault();
				var href = this.getAttribute("href");
				var elem = document.querySelector(href) || document.querySelector("a[name="+href.substring(1, href.length)+"]");
				//gets Element with an id of the link's href 
				//or an anchor tag with a name attribute of the href of the link without the #
				
				a.classList.add('active');
				Array.from(navbarlinks).map(nav => {
					var list = nav.getAttribute("href")
					if (href != list) {
						nav.classList.remove('active');
					}
					//console.log(b.getAttribute("href"))
				})

				
				window.scroll({
					top: elem.offsetTop - 300, 
					left: 0, 
					behavior: 'smooth' 
				});
				//if you want to add the hash to window.location.hash
				//you will need to use setTimeout to prevent losing the smooth scrolling behavior
			   //the following code will work for that purpose
			   setTimeout(function(){
					window.location.hash = this.hash;
				}, 5000); 
			});
		});
		
	})
	
	const onMouseOver = () => {
		setHover(true);
		//console.log("test")
	}
	
	const onMouseOut = () => {
		//console.log(hover)
		setHover(false);
	}
	
	const handleHoverShow = () => {
		//console.log("mouse over show : " );
		document.querySelector("#customHeaderMenu").classList.add('show');
	}
	
	const handleHoverHide = () => {
		//console.log("mouse over hide : " );
		document.querySelector("#customHeaderMenu").classList.remove('show');
	}
		
	return (
		<div className="app-header" id="home">
			<Container fluid className="px-3 py-1 bg-darkred text-white">
				<Row>
					<Col className="col-auto me-auto">
						<ul className="top-hmenu">
							<li><i className="bi bi-envelope icon"></i> contactus@dragoncomtrading.com <i className="px-2"></i></li>
							<li><i className="bi bi-telephone icon"></i> (02) 7000-1560 <i className="px-2"></i></li>
							<li><i className="bi bi-phone icon"></i> +63 963 128 5777 | +63 963 685 0287 <i className="px-2"></i></li>
						</ul>
					</Col>
					<Col className="col-auto">	
						<a href="https://www.facebook.com/DragoncomTrading/about_details/" className="text-white"><i className="bi bi-facebook"></i></a> &nbsp;
						<a href="https://www.tiktok.com/@dragoncomtco" className="text-white"><i className="bi bi-tiktok"></i></a>
					</Col>	
				</Row>				
			</Container>
			
			{(() => {
				if(isMobile) {
					return (
						<>
						<Navbar bg="light" expand="lg" className="py-0">
							<Container>
								<Navbar.Brand href="#home">
									<Image src={logo} width="150"/> 
								</Navbar.Brand>
								<Navbar.Toggle aria-controls="basic-navbar-nav" style={{display:'block'}} onClick={handleShow}/>
							</Container>
						</Navbar>
						<Offcanvas show={show} onHide={handleClose}>
							<Offcanvas.Header closeButton>
								<Offcanvas.Title>
									<Image src={logo} width="150"/> 
								</Offcanvas.Title>
							</Offcanvas.Header>
							<Offcanvas.Body>
								<ListGroup variant="flush">
									<ListGroup.Item>
										<a href="">Home</a>
									</ListGroup.Item>
									<ListGroup.Item>
										<a href="#about-us">About Us</a>
									</ListGroup.Item>
									<ListGroup.Item>
										<a href="#products">Products</a>
									</ListGroup.Item>
									<ListGroup.Item>
										<a href="#contact-us">Contact Us</a>
									</ListGroup.Item>
								</ListGroup> <br />
								<Row className="g-0 d-none">
									<Col>
										<a className="btn btn-primary rounded-pill lazada" href="https://www.lazada.com.ph/shop/dragoncom-trading-corp1621582220/?path=index.htm" role="button">
											<i className="bi bi-cart3"></i> Lazada
										</a>
									</Col>
									<Col>
										<a className="btn btn-danger rounded-pill shopee" href="https://shp.ee/wxb3z5u" role="button">
											<i className="bi bi-cart3"></i> Shopee
										</a>
									</Col>
								</Row>
							</Offcanvas.Body>
						</Offcanvas>
						</>
						)
				} else {
					return (
						<Navbar bg="light" expand="lg" className="py-0">						
							<Container>
								<Navbar.Brand href="#home">
									<Image src={logo} width="250"/> 
								</Navbar.Brand>
								<Navbar.Toggle aria-controls="basic-navbar-nav" />
								<Navbar.Collapse className="justify-content-center">
									<Nav className="justify-content-end" activeKey="/home">
										<Nav.Item onMouseOver={ handleHoverHide }>
											<Nav.Link href="#home">Home</Nav.Link>
										</Nav.Item>
										<Nav.Item onMouseOver={ handleHoverHide }>
											<Nav.Link eventKey="aboutUs"  href="#aboutUs">About Us</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link 
												eventKey="products" 
												href="#products"
												onMouseOver={ handleHoverShow }>Products
											</Nav.Link>
																					
											<ul 
												className="dropdown-menu" 
												id="customHeaderMenu" 
												aria-labelledby="navbarDropdown"
												onMouseLeave={ handleHoverHide }>
											{
												menu.map((obj, index) => {
													
													const subProductSize = "noArrowRight" //obj.subProduct.length > 0 ? "" : "noArrowRight"
													return (
														<li className="nav-item dropdown dropend">
															<a 
																className={`nav-link dropdown-toggle ${subProductSize}`}
																
																id="navbarDropdown" 
																role="button" 
																data-bs-toggle="dropdown" 
																aria-expanded="false"
																title={ `Click to view more > ${obj.name}` }
																onClick={ () => handleShow( `${ obj.name }`, `${ obj.parent }`) }> { obj.name }
															</a>
															
															{/*	
															if (obj.subProduct.length > 0)
															{
																<ul 
																	className="dropdown-menu show" 
																	style={{position:"absolute", inset: "0px auto auto 0px", margin: "0px", transform: "translate(165px, 20px)" }} 
																	data-popper-placement="right-start" 
																	aria-labelledby="navbarDropdown">
																
																obj.subProduct.map((subObj, subIndex) => {
																	return (
																		<li><a className="dropdown-item" href="#">{ subObj.name }</a></li>
																	)
																})
															}
															
															*/}
															
														</li>
													);
												})
											}
											</ul>
											
										</Nav.Item>
										<Nav.Item onMouseOver={ handleHoverHide }>
											<Nav.Link eventKey="contactUs"  href="#contactUs">Contact Us</Nav.Link>
										</Nav.Item>
									</Nav>
								</Navbar.Collapse>
								
								<div className="justify-content-end shopat d-none">
									<Row className="g-2">
										<Col>
											<a className="btn btn-primary rounded-pill lazada" href="https://www.lazada.com.ph/shop/dragoncom-trading-corp1621582220/?path=index.htm" role="button">
												<i className="bi bi-cart3"></i> Lazada
											</a>
										</Col>
										<Col>
											<a className="btn btn-danger rounded-pill shopee" href="https://shp.ee/wxb3z5u" role="button">
												<i className="bi bi-cart3"></i> Shopee
											</a>
										</Col>
									</Row>
								</div>
							</Container>
						</Navbar>
					)
				}
			})()}
			
			<Modal 
				show={show} 
				fullscreen 
				onHide={() => setShow(false)}
				id="ModalChildProduct">
				<Modal.Header closeButton>
					<Modal.Title>{ title } Products</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="px-2">
						{ ProductModalContent(productNumber, childProductNumber) }
					</div>
				</Modal.Body>
			</Modal>
			
		</div>
	);
}

export default Header;

