import { useState } from "react";
import $ from "jquery";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';

/**
[1] 26/08/2023 Change Email and contact number
*/

function ContactUs() {
	
	const [users, setUsers] = useState(
		{ 
			name: "", 
			email: "", 
			company: "",
			city: "",
			subject: "", 
			message: "" 
		}
	);
	const [hasStatus, setHasStatus] = useState(false);
	const [isLoading, setIsLoading] = useState("");
	const [disabled, setDisabled] = useState(false);
	
	const handleChange = (event) => {
		setUsers({
			...users,
			[event.target.name]: event.target.value,
		});
	}
	
	const handleSumbit = (event) => {
		event.preventDefault();
		
		//setHasStatus(false);
		setIsLoading(true)
		setDisabled(true);
		
		const form = $(event.target);
		$.ajax({
            type: "POST",
            url: form.attr("action"),
            data: form.serialize(),
            success(data) {
                //console.log(data)
				//setResult(data);
				setHasStatus(true);
				setIsLoading(false);
				setDisabled(false);
				
				let emptyvals = { 
					name: "", 
					email: "", 
					company: "",
					city: "",
					subject: "", 
					message: "" 
				};
				
				setUsers(emptyvals);
            },
        })
	};

	//-----------
	const validateEmail = (email) => {
		const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
		const result = pattern.test(email);
		if(result===true){
			this.setState({
				emailError:false,
				email:email
			})
		} else{
			this.setState({
				emailError:true
			})
		}
	}
	
	const baseUrl = (page, port) => {
		var hostname = window.location.hostname;
		var protocol = window.location.protocol;
		var url ="";
		var protocol = (protocol === "http") ? protocol : "https";
		//var protocol = 'http';
		
		if(page)
		{
			if(port)
				url = `${protocol}://${hostname}:${port}/${page}`;
			else
				url = `${protocol}://${hostname}/${page}`;
		}
		else 
		{
			url = `${protocol}://${hostname}`;	
		}
		return url;
	}
	
	return (
	<div id="contactUs" className="contact-us" data-aos="fade-up">
		<Container>
			<h2 className="text-center page-title" data-aos="zoom-in-down" data-aos-duration="1500">Need Help? <span>Contact Us</span></h2>
			<div className="mb-3">
				<iframe 
					style={{border:0, width:'100%', height:'350px'}} 
					src="https://maps.google.com/maps?q=Dragoncom%20Ttrading%20Corporation&t=&z=13&ie=UTF8&iwloc=&output=embed" 
					frameborder="0" 
					scrolling="no" 
					marginheight="0" 
					marginwidth="0">
				</iframe>
			</div>

			<Row className="gy-4">
				<Col xs={12} md={6}>
					<div className="info-item d-flex align-items-center" data-aos="fade-right">
						<i className="icon bi bi-map flex-shrink-0"></i>
						<div>
							<h3>Our Address</h3>
							<p>4th Floor Castro Bldg. 58 Timog Ave, Diliman, Quezon City</p>
						</div>
					</div>
				</Col>
				<Col xs={12} md={6}>
					<div className="info-item d-flex align-items-center" data-aos="fade-left">
						<i className="icon bi bi-envelope flex-shrink-0"></i>
						<div>
							<h3>Email Us</h3>
							{/*<p>Dragoncomtradingcorp@gmail.com</p>*/}
							<p>contactus@dragoncomtrading.com</p>
						</div>
					</div>
				</Col>
				<Col xs={12} md={6}>
					<div className="info-item  d-flex align-items-center" data-aos="fade-right">
						<i className="icon bi bi-telephone flex-shrink-0"></i>
						<div>
							<h3>Call Us</h3>
							<p>(02) 7000-1560</p>
							<p>+63 963 128 5777 | +63 963 685 0287</p>
						</div>
					</div>
				</Col>
				<Col xs={12} md={6}>
					<div className="info-item  d-flex align-items-center" data-aos="fade-left">
						<i className="icon bi bi-hourglass flex-shrink-0"></i>
						<div>
							<h3>Opening Hours</h3>
							<div>
								<strong>Mon-Sat:</strong> 9AM - 6PM; &nbsp;
								<strong>Sunday:</strong> Closed
							</div>
						</div>
					</div>
				</Col>
			</Row>
			<Form 
				action= {baseUrl('backend/contact.php', '')}
				method="post" 
				role="form" 
				className="form" 
				onSubmit={(event) => handleSumbit(event)}>
				<Row>
					<Col xs={12} md={6}>
						<div className="my-4" data-aos="fade-up">
							<Form.Control size="lg" name="name" type="text" placeholder="Your name*" required
							value={users.name}
							onChange={(event) => handleChange(event)}
							disabled={disabled}
							/>
						</div>
					</Col>
					<Col xs={12} md={6}>
						<div className="my-4" data-aos="fade-up">
							<Form.Control size="lg" name="email" type="email" placeholder="Your Email*" required
							value={users.email}
							onChange={(event) => handleChange(event)}
							disabled={disabled}
							/>
						</div>
					</Col>
					<Col xs={12} md={6}>
						<div className="mb-4" data-aos="fade-up">
							<Form.Control size="lg" name="company" type="text" placeholder="Company Name*" required
							value={users.company}
							onChange={(event) => handleChange(event)}
							disabled={disabled}
							/>
						</div>
					</Col>
					<Col xs={12} md={6}>
						<div className="mb-4" data-aos="fade-up">
							<Form.Control size="lg" name="city" type="city" placeholder="City*" required
							value={users.city}
							onChange={(event) => handleChange(event)}
							disabled={disabled}
							/>
						</div>
					</Col>
					<Col xs={12} md={12}>
						<div className="mb-4" data-aos="fade-up">
							<Form.Select size="lg" className="text-muted" name="whereFindWebSite"disabled={disabled}>
								<option value="Google Search">How do you find our web site?</option>
								<option value="Google Search">Google Search</option>
								<option value="Events">Events</option>
								<option value="Advertisement">Advertisement</option>
							</Form.Select>
						</div>
					</Col>
					<Col xs={12} md={12}>
						<div className="mb-4" data-aos="fade-up">
							<Form.Control size="lg" name="subject" type="text" placeholder="Subject*" required
							value={users.subject}
							onChange={(event) => handleChange(event)}
							disabled={disabled}
							/>
						</div>
					</Col>
					<Col xs={12} md={12}>
						<div className="mb-4" data-aos="fade-up">
							<Form.Control as="textarea" size="lg" name="message" rows={4} placeholder="Message*"
							value={users.message}
							onChange={(event) => handleChange(event)}
							disabled={disabled}
							/>
						</div>
					</Col>
					<Col xs={12} className="my-1" data-aos="fade-up">
						<div className="mb-4  d-flex justify-content-center">
							{
								(() => {
									if(isLoading === true) {
										return(
											<Button variant="danger" size="lg" disabled>
												<Spinner
													as="span"
													animation="border"
													size="lg"
													role="status"
													aria-hidden="true"
												/>
												<span> Loading...</span>
											</Button>
										)
									} else {
										return(
											<Button variant="danger" size="lg" type="submit">
												Send Message
											</Button>
										)
									}
								})()
							}	
						</div>
						
						{
							(() => {
								if (hasStatus === true)
								{
									return (
										<div className="text-center">
											<Alert key="success" variant="success">
												MAIL HAS BEEN SENT SUCCESSFULLY
											</Alert>
										</div>
									)
								}
							})()
						}	
						
					</Col>
				</Row>
			</Form>
		</Container>
	</div>
	);
}

export default ContactUs;